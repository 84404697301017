function ArtificialIntel() {

    return (
        <div className="row form-group">
            <h2 className="python-examples col-sm-12">ArtificialIntelligence</h2>
            <div className="row">
                <span>info + testing here</span>
            </div>
        </div>
    );
}

export default ArtificialIntel;