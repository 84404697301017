function Excel() {

    return (
        <div className="row form-group">
            <h4 className="php-examples col-sm-12">Excel</h4>
            <div className="row">
                <span>uses PHPSpreadSheet</span>
                <span>download button, add row (use react table, all local JS)</span>
                <span>import csv to excel button</span>
            </div>
        </div>
    );
}

export default Excel;